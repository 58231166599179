import { Link, graphql } from 'gatsby';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import Donation from '../components/Donation';
import ProjectsLists from '../components/Donation/ProjectsLists';
import { getListDonationProjectForSelect } from '../utils/functions';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import DonationProjectCard from '../components/Donation/DonationProjectCard';
import GiveDonateForm from '../components/NewGive/DonateForm';
import DonorboxForm from '../components/NewGive/DonorForm';
import { LJCampaignUrl } from '../constants/donorboxcampaign';
const DonatePage = (props) => {
  const {
    data: { bgGive, patreonThumb, donationsDetails, languageDonationPage },
  } = props;
  const donationDetailsData = donationsDetails.edges[0].node.data;
  const donationProjects = donationDetailsData.projects;

  const customProjectsLists = getListDonationProjectForSelect(
    donationProjects,
    languageDonationPage.edges[0].node.data.body
  );

  const featuredProjects = donationProjects.filter((item) => item.featured);
  const otherProjects = donationProjects.filter((item) => !item.featured && !item.hideInListings);

  const defaultSelectedProject = customProjectsLists.find((item) => item.isDefaultSelected);

  const languageProjectTotalGoal = languageDonationPage.edges[0].node.data.body.reduce(
    (prev, current) => prev + current.primary.goal,
    0
  );

  return (
    <BodyClassName className="body-light page-give">
      <DefaultLayoutComponent title="Donate">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={donationDetailsData.backgroundImage.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pb-container-donate ">
                <div className="pb-content">
                  <h1 className="p-xl give-donate-heading"> Give</h1>
                </div>
                <div className="pb-content">
                  <p className="p-xl donate-sub-title"> {donationDetailsData.headingDescriptions.text}</p>
                </div>
                <div className="give-donate-btn" style={{ marginTop: '56px' }}>
                  <Link to="#donate-box" className="btn btn-sm btn-primary-ii btn-watch-now" style={{ width: '200px' }}>
                    Give
                  </Link>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <section className="give-container over_lpd-container bg-dark pd-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section bg-light give ">
                <ProjectsLists
                  featuredProjects={featuredProjects}
                  otherProjects={otherProjects}
                  languageProjectTotalGoal={languageProjectTotalGoal}
                />
              </div>
            </div>
          </section>

          <section className="give-container  bg-dark pd-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <div id="donate-box" className="bg-off-white donate-box  give pd-b-0">
                <DonorboxForm src={LJCampaignUrl} />
              </div>
              <div style={
                {
                  backgroundColor:"#F4F5F7"
                }
              } className="section method-footer method-note">
                <div className="wrapper wrapper-md pd-x-md">
                  <h6 className="note-title">Thank you so much for supporting Lineage Journey!</h6>
                  <div className="note-text">
                    <p>
                      Lineage Journey is a registered non-profit organisation (in the UK) and relies on crowdfunding and
                      the generous donations of our supporters to produce high quality videos and resources. With an
                      average donation of less than $30, monthly (Patreon) supporters make our work possible through
                      their kindness and generosity. All our videos are available for free, thanks to your help.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgGive: file(relativePath: { eq: "bg-give.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    patreonThumb: file(relativePath: { eq: "patreon-thumb.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    languageDonationPage: allPrismicLanguageDonationsPage {
      edges {
        node {
          data {
            body {
              ... on PrismicLanguageDonationsPageBodyLanguageProjects {
                id
                primary {
                  languageName: language_name {
                    text
                  }
                  flagCode: flag_code {
                    text
                  }
                  goal
                  continent
                }
              }
            }
          }
        }
      }
    }
    donationsDetails: allPrismicDonationsPage {
      edges {
        node {
          data {
            headingDescriptions: heading_descriptions {
              text
            }
            backgroundImage: background_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            projects {
              title {
                text
              }
              thumbnail {
                url
              }
              goal
              shortDescriptions: short_descriptions {
                text
              }
              featured
              isDefaultSelected: isdefaultselected
              hideInListings: hideinlistings
              fundsRaised: funds_raised
              detailsPage: details_page {
                id
                slug
                uid
                document {
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DonatePage;
